@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

legend {
  display: block;
}

.converted-unit-dropdown {
  width: 160px;
}
